import React from 'react';
import io from "socket.io-client";

export const socket = io(
	{
		reconnect: true,
		transports: ['websocket', 'polling', 'flashsocket'],
	},
);

export const SocketContext = React.createContext();